import { reIndexTableRows } from '../utils/re_index_template_unit_rows';
import selectizeConfig from '../utils/selectize_config';

export default class DuplicateRow {
  constructor(table, callBackAtTableRowAddition = () => {}) {
    this.table = table;
    this.callBackAtTableRowAddition = callBackAtTableRowAddition;
  }

  init() {
    this.initHandlers();
    reIndexTableRows();
  }

  initHandlers() {
    this.table.on('click', e => {
      const $target = $(e.target);
      if ($target.closest('.j-duplicate-row:not(.disabled)').length) {
        const originTargetIndex = $target.closest('tr').attr('data-local-index');

        $('.j-placement-container, .j-utm-container').each((_, container) => {
          const $table = $(container).find('table');
          this.duplicateRow($table, originTargetIndex);
        });
      }
    });
  }

  async duplicateRow(table, trIndex) {
    let $tr = table.find(`tr[data-local-index="${trIndex}"]`);
    this.updateSelectize($tr.find('select'));

    if ($tr.length === 0 && trIndex !== 0) {
      await this.duplicateRow(table, trIndex - 1);
      $tr = table.find(`tr[data-local-index="${trIndex}"]`);
    }

    const rowId = $tr.attr('data-row-id');

    if (rowId) {
      await this.sendRequestDuplicate({ row_id: rowId }, table, $tr, $tr.clone(true, false));
      table.trigger('duplicateItem');
      this.computeRemoveButtonDisableStatus();
    }

    this.updateSelectize($tr.find('select'));
  }

  async sendRequestDuplicate(sendingData, table, trOrigin, trClone) {
    const blockName = table.attr('data-block');
    const response = $.ajax({
      method: 'POST',
      data: JSON.stringify(sendingData),
      url: `/builder/${blockName}/duplicate`,
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
    });

    await response
      .then(data => {
        this.insertResult(data, table, trOrigin, trClone);
      })
      .catch(err => {
        console.error(err);
      });
  }

  insertResult(data, table, trOrigin, trClone) {
    if (trOrigin.find('select').length) {
      trOrigin.find('select').each((index, item) => {
        $(trClone).find('select').eq(index).val($(item).val());
      });
    }

    if (trClone.find('.input-daterange.initialized').length) {
      trClone.find('.input-daterange.initialized').each((index, item) => {
        $(item).removeClass('initialized');
      });
    }
    table.find('tbody').append(trClone);
    trClone.attr('data-row-id', data.new_row_id);
    reIndexTableRows();
    this.callBackAtTableRowAddition();
  }

  computeRemoveButtonDisableStatus() {
    $('.j-placement-container, .j-utm-container').each((_, container) => {
      const $tableRows = $(container).find('tbody tr');

      if ($tableRows.length > 1) {
        $tableRows.find('.j-delete-row').removeClass('disabled').attr('data-bs-toggle', 'modal');
      }
    });
  }

  updateSelectize(selects) {
    selects.each((_, select) => {
      const $select = $(select);
      if ($select.hasClass('selectized') && $select[0].selectize) {
        $select[0].selectize.refreshState();
        $select[0].selectize.updateOriginalInput();
        $select[0].selectize.destroy();
      } else {
        $select.removeClass('form-control').selectize(selectizeConfig);
        $select[0].selectize.refreshState();
        $select.addClass('form-control');
        $select.parents('.table-responsive').addClass('has-selectize');
      }
    });
  }
}

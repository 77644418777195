import ClipboardJS from 'clipboard';
import tableNotification from './tableNotification';
import AlertNotification from './AlertNotification';

const AutofilledUtmField = {
  campaignName: 'campaignName',
  adSet: 'adSet',
  ads: 'ads',
};

export default class GenerateResult {
  constructor(table, onGenerateClick = () => {}) {
    this.table = table;
    this.onGenerateClick = onGenerateClick;
    this.resultContainer = this.table.closest('.card').find('.j-result-container');
    this.blockName = this.table.attr('data-block');
    this.timeout = '';
    this.isShowingError = false;
    this.isValid = false;
    this.hasContent = false;
    this.containerId = this.table.closest('.j-container').attr('data-container-id');
    this.generatedArrayResult = [];
    // this.popupTemplate = $('<span class="custom-tooltip-popover"><span class="custom-tooltip">Сopied Successfully</span></span>');
  }

  init() {
    this.initHandlers();
  }

  initHandlers() {
    this.table.closest('.card').on('click', e => {
      const $target = $(e.target);
      if ($target.hasClass('j-generate-result')) {
        this.clickHandlerFunction();
      } else if ($target.hasClass('j-result-clear')) {
        this.clearResult();
      }
    });
  }

  initCopyToClipboard() {
    const clipboardButton = this.resultContainer.find('.j-result-copy')[0];

    this.clipboard = new ClipboardJS(clipboardButton);
    this.clipboard.on('success', () => {
      tableNotification.showMessageByTime(this.containerId, 'Copied Successfully', 'success', 1500);
    });
  }

  clickHandlerFunction() {
    const $form = this.table.closest('.card').find('form');

    if ($form && $form[0] && $form[0].hasOwnProperty('checkValidity')) {
      $form[0].checkValidity();
    }

    if (!$form.hasClass('was-validated')) {
      $form.addClass('was-validated');
    }

    this.checkValidation();
    if (this.blockName === 'utm') {
      this.generateUtmResult();
    } else {
      this.generateCampaignAndPlacementResult();
    }
    this.showResult();
  }

  clearResult() {
    if (this.resultContainer.find('.text-area').length) {
      this.resultContainer.find('.text-area').html('');
      $('.j-result-copy').attr('data-clipboard-text', '');
      this.hasContent = false;
    }
  }

  checkValidation() {
    this.hasErrors = false;

    this.table.find('.row-not-valid').each((index, item) => {
      $(item).removeClass('row-not-valid');
    });

    this.table.find('select, input').each((index, item) => {
      const $item = $(item);
      if (!$item.is(':disabled')) {
        if ($item.is(':required')) {
          if (
            ($item.find('option:selected').length &&
              ($item.find('option:selected').text().trim() === '' ||
                $item.find('option:selected').text().trim() === 'None selected')) ||
            $item.is(':invalid') ||
            ($item.is('input') && $item.val().trim() === '')
          ) {
            if (!$item.hasClass('not-valid')) {
              $item.addClass('not-valid');
            }
            this.hasErrors = true;
            if (!$item.closest('tr').hasClass('row-not-valid')) {
              $item.closest('tr').addClass('row-not-valid');
            }
          } else {
            if ($item.hasClass('not-valid')) {
              $item.removeClass('not-valid');
            }
          }
        } else {
          if ($item.is(':invalid')) {
            if (!$item.hasClass('not-valid')) {
              $item.addClass('not-valid');
            }
            this.hasErrors = true;
            if (!$item.closest('tr').hasClass('row-not-valid')) {
              $item.closest('tr').addClass('row-not-valid');
            }
          } else {
            if ($item.hasClass('not-valid')) {
              $item.removeClass('not-valid');
            }
          }
        }
      }
    });

    if (this.hasErrors) {
      this.isValid = false;
      this.showError('Some fields are not valid');
    } else {
      this.isValid = true;
      this.removeError();
    }
  }

  generateCampaignAndPlacementResult() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }

    this.generatedArrayResult = [];
    const isApplyLvCustomLogic = global.isApplyLvCustomLogic;

    this.table.find('tbody tr').each((index, itemTr) => {
      let textResult = '';
      if (index > 0) {
        this.clipboardResult += '\n';
      }
      const $itemTr = $(itemTr);
      let row_id = $itemTr.attr('data-row-id');
      let indexForDash = 0;

      if (!$itemTr.hasClass('row-not-valid')) {
        $itemTr.find('.form-control:not([data-disabled=true])').each((index, itemControl) => {
          const $itemControl = $(itemControl);
          const th = this.table.find('thead th')[index];
          const thText = th.innerText.replace(/[*?]/g, '').trim().toLowerCase();
          let shortText = '';

          if (!$itemControl.is(':disabled')) {
            if ($itemControl.is('input')) {
              shortText = $itemControl.val();
            } else {
              shortText = this.getSelectInputValue($itemControl);
            }
          } else {
            if ($itemControl.is('select')) {
              let selectedShortValue;

              if ($itemControl.hasClass('selectized')) {
                selectedShortValue = $itemControl
                  .siblings('div.selectize-control')
                  .find('.item')
                  .attr('data-short-value');
              } else {
                selectedShortValue = $itemControl.find('option:selected').attr('data-short-value');
              }

              if (selectedShortValue) {
                if (selectedShortValue === '__empty') {
                  shortText = 'empty';
                }
              }
            }
          }

          const isDefinedNotBlank = shortText !== '' && typeof shortText !== 'undefined';
          const isZeroSelected = shortText === 'zeroselected';
          const isZeroSelectedNotRequired = isZeroSelected && !$itemControl.is(':required');
          const isEmpty = shortText === 'empty';
          const isBlankAndNotRequired = shortText === '' && !$itemControl.is(':required');

          if (isDefinedNotBlank || isZeroSelectedNotRequired || isEmpty || isBlankAndNotRequired) {
            if (indexForDash > 0) {
              if (isApplyLvCustomLogic && (thText === 'media agency name' || thText === 'country code of agency')) {
                textResult += '_';
              } else if (shortText !== '') {
                textResult += global.vars.templateDelimiter;
              }
            }

            if (isEmpty || isZeroSelected) {
              textResult += '';
            } else {
              textResult += shortText;
            }
            indexForDash++;
          } else {
            textResult = '';
            return false;
          }

          textResult = textResult.toUpperCase();
        });
      }

      this.generatedArrayResult.push({
        id: row_id,
        order: index + 1,
        text: textResult,
      });
    });
  }

  generateUtmResult() {
    this.generatedArrayResult = [];

    const { campaignName, adSets, ads } = this.onGenerateClick();

    const campaignNameInputs = this.table.find(
      `input[data-dimension-autofill-from="${AutofilledUtmField.campaignName}"]`
    );
    const campaignTermInputs = this.table.find(`input[data-dimension-autofill-from="${AutofilledUtmField.adSet}"]`);
    const campaignContentInputs = this.table.find(`input[data-dimension-autofill-from="${AutofilledUtmField.ads}"]`);

    if (campaignNameInputs.length) {
      campaignNameInputs.val(campaignName);
      if (campaignName === '') {
        return;
      }
    }

    if (campaignTermInputs.length) {
      campaignTermInputs.each(function (index) {
        $(this).val(adSets[index]);
      });
      if (adSets.some(s => s === '')) {
        return;
      }
    }

    if (campaignContentInputs.length) {
      campaignContentInputs.each(function (index) {
        $(this).val(ads[index]);
      });
      if (ads.some(s => s === '')) {
        return;
      }
    }

    this.table.find('tbody tr').each((trIndex, itemTr) => {
      const $itemTr = $(itemTr);
      const rowId = $itemTr.attr('data-row-id');
      let utmUrl = '';
      let startUrl = '';

      if ($itemTr.hasClass('row-not-valid')) {
        this.generatedArrayResult.push({ id: rowId, order: trIndex + 1, text: '' });
      } else {
        $itemTr.find('.form-control:not([data-disabled=true])').each((inputIndex, itemControl) => {
          const $itemControl = $(itemControl);
          const value = $itemControl.is('input') ? $itemControl.val() : this.getSelectInputValue($itemControl);
          const delimiter = $itemControl.attr('data-dimension-delimiter');

          if (inputIndex === 0) {
            startUrl = value;

            return;
          }

          if (inputIndex === 1) {
            utmUrl += '?';
          }

          if (value) {
            if (inputIndex > 1) {
              utmUrl += '&';
            }

            utmUrl += delimiter ? `${delimiter}=${value}` : value;
          }

          utmUrl = utmUrl.toLowerCase();
        });

        utmUrl = startUrl + utmUrl;

        this.generatedArrayResult.push({
          id: rowId,
          order: trIndex + 1,
          text: utmUrl,
        });
      }
    });
  }

  showResult() {
    const data = {
      container_id: this.containerId,
      generated_values: this.generatedArrayResult,
      clipboard_text: this.generateClipboardText(this.generatedArrayResult),
    };
    $.ajax({
      method: 'POST',
      data: JSON.stringify(data),
      url: '/builder/render_generated_values',
      contentType: 'application/json; charset=utf-8',
    })
      .done(htmlRow => {
        this.resultContainer.html(htmlRow);
        this.initCopyToClipboard();
        this.hasContent = true;
      })
      .fail(err => {
        this.showError(err.responseJSON.error);
      });
  }

  showError(text) {
    AlertNotification.AppendHtml('error', text, this.table.closest('.card'), true);
    // tableNotification.showMessageByTime(this.blockName, text, 'error', 2000);
  }

  removeError() {
    AlertNotification.RemoveHtml(this.table.closest('.card'), true);
  }

  generateClipboardText(generatedValues) {
    return generatedValues.map(value => value.text).join('\n');
  }

  getSelectInputValue(select) {
    let selectedShortValue;

    if (select.hasClass('selectized')) {
      selectedShortValue = select.siblings('div.selectize-control').find('.item').attr('data-short-value');
    } else {
      selectedShortValue = select.find('option:selected').attr('data-short-value');
    }

    if (selectedShortValue === '__none_selected' || selectedShortValue === 'None selected') {
      return 'zeroselected';
    } else if (selectedShortValue === '__empty') {
      return 'empty';
    } else {
      return selectedShortValue;
    }
  }
}

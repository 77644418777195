import { reIndexTableRows } from '../utils/re_index_template_unit_rows';
import { collectInputsAndAddListener } from '../utils/use_input_cleaning_pattern';

export default class NewRow {
  constructor(prepareDataTryToSend, callBackAtTableRowAddition = () => {}) {
    this.prepareDataTryToSend = prepareDataTryToSend;
    this.callBackAtTableRowAddition = callBackAtTableRowAddition;
  }

  init() {
    $('.j-new-placement-or-utm').on('click', () => {
      const $containers = $('.j-placement-container, .j-utm-container');
      const trCountPerContainer = this.getTrCountPerContainers($containers.toArray());

      const maxTrCount = Math.max(...Object.values(trCountPerContainer));

      $containers.each((_, container) => {
        const $container = $(container);
        const containerId = $container.attr('data-container-id');
        const $button = $container.find('.j-new-placement-or-utm');

        const numberOfTrToCreate = maxTrCount - trCountPerContainer[containerId] + 1;
        for (let index = 0; index < numberOfTrToCreate; index++) {
          this.createNewRow($button);
        }
      });
    });
  }

  createNewRow(button) {
    const $container = button.closest('.j-container');
    const $table = $container.find('table');

    const url = button.attr('data-href');

    $.ajax({ method: 'GET', url })
      .then(data => {
        const tr = this.update($table, data);
        this.prepareDataTryToSend(tr, $table.attr('data-block'), $container.attr('data-container-id'));

        setTimeout(() => {
          collectInputsAndAddListener();
        }, 300);
      })
      .catch(err => {
        console.error(err);
      });
  }

  update(table, data) {
    const tr = $(data);
    table.find('tbody').append(tr);
    this.preparePlacementToLoadData(tr);
    this.callBackAtTableRowAddition();
    reIndexTableRows();

    return tr;
  }

  preparePlacementToLoadData(tr) {
    tr.find('.form-control').each((_, item) => {
      const $item = $(item);
      if ($item.attr('data-parent-id')) {
        $(`.j-campaign-table [data-column-id="${$item.attr('data-parent-id')}"]`).trigger('change', {
          action: 'add-new',
          tr: tr,
        });
      }

      if ($item.attr('data-mother-id')) {
        $(`.j-campaign-table [data-column-id="${$item.attr('data-mother-id')}"]`).trigger('change', {
          action: 'add-new',
          tr: tr,
        });
      }
    });
  }

  getTrCountPerContainers(containers) {
    return containers.reduce((counts, container) => {
      const $container = $(container);
      const containerId = $container.attr('data-container-id');
      const trCount = $container.find('tbody tr').length;

      return { ...counts, [containerId]: trCount };
    }, {});
  }
}
